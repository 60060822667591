import React from "react";
import { Box, Heading, Center, Flex } from "@chakra-ui/react";
import { Slice } from "./Slice";
import { Element } from "./Element";
import volumeImage from "../nmc_render_black_green.png";

const Volume = () => {
  return (
    <Box>
      <Center>
      {/* <img src={volumeImage}  style={{opacity: 0.01}}/> */}
      <span >
        
      </span>
      </Center>
    </Box>
  );
};

export const Landing = () => {
  return (
    <Slice
      roundedBottom={true}
      // TODO Consider height here...
      h={["800px"]}
      backgroundColor="white"
      // style={{ overflow: "hidden",background: 'linear-gradient(16deg, #0a0a0a, #ffffff 20%, #ffffff)' }}
    >
      <Flex 
        justify="center"
        align="center"
        direction="column"
        h="100%">
      <Element>
        
        <Heading
          color="black"
          fontSize={["xl", "2xl", "6xl"]}
          size="xl"
          textAlign="center"
          fontWeight={200}
          // pb={6}
          // pt={24}
        >
          Material Design.
        </Heading>
        <Heading
          color="black"
          fontSize={["xl", "2xl", "6xl"]}
          size="xl"
          textAlign="center"
          fontWeight={100}
          pb={24}
          pt={0}
        >
          <span style={{
            backgroundColor: "#0a0a0a",
            backgroundImage: "linear-gradient(45deg, #D1F752, #0a0a0a)",
            backgroundSize: "100%",
            WebkitBackgroundClip: "text",
            MozBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
            MozTextFillColor: "transparent"}}>Supercharged</span> with AI.
        </Heading>
        {/* <Volume /> */}
      </Element>
      </Flex>
    </Slice>
  );
};
