import { Slice } from "./Slice";
import {
  Text,
  Box,
  Link,
  Button,
  VStack,
  HStack,
  Flex,
  Spacer,
} from "@chakra-ui/react";
import { SIGNUP_URL } from "../App";
import { Element } from "./Element";

export const Footer = () => {
  return (
    <Slice mt="8" pb="1" roundedTop={true} backgroundColor="gray.100">
      <Element maxWidth="600px">
        <Flex align="center" justify="center">
          <Link
            textDecor="underline"
            textAlign="center"
            w="100%"
            href="https://twitter.com/polaronAI"
          >
            Twitter
          </Link>
          <Link
            textDecor="underline"
            textAlign="center"
            w="100%"
            href="https://uk.linkedin.com/company/polaron-ai"
          >
            LinkedIn
          </Link>
          <Link
            textDecor="underline"
            textAlign="center"
            w="100%"
            href={SIGNUP_URL}
          >
            Contact us
          </Link>
        </Flex>
        <Flex align="center" justify="center" mt={4}>
        <Link
            textDecor="underline"
            textAlign="center"
            w="100%"
            href="https://polaron.ai/privacy-policy"
            fontSize='sm'
          >
            Privacy Policy
          </Link>
          <Link
            textDecor="underline"
            textAlign="center"
            w="100%"
            href="https://polaron.ai/terms-of-use"
            fontSize='sm'
          >
            Terms of Use
          </Link>
        </Flex>
        <Text mt="4" textAlign="center" fontSize="sm">
          © Polaron Ltd 2024
        </Text>
        
        <Text mt="4" textAlign="center" fontSize="sm">
          Polaron LTD is registered in England and Wales 14882059 with its
          registered office at 30 Upper High Street, Thame, Oxfordshire, OX9
          3EZ, UK
        </Text>
      </Element>
    </Slice>
  );
  return (
    <Slice mt="8" pb="1" roundedTop={true} backgroundColor="gray.100">
      <Element maxWidth="600px">
        <HStack>
          <Box
            p={["2", "4"]}
            w="50%"
            borderRight={"2px solid"}
            borderColor="gray.300"
          >
            <Text fontSize="sm">
              Polaron LTD is registered in England and Wales 14882059 with its
              registered office at 30 Upper High Street, Thame, Oxfordshire, OX9
              3EZ, UK
            </Text>
          </Box>

          <VStack p={["2", "4"]} w="50%">
            <Link href="https://twitter.com/polaronAI">Twitter</Link>
            <Link href="https://uk.linkedin.com/company/polaron-ai">
              LinkedIn
            </Link>
            <Button
              as={Link}
              variant="filled"
              backgroundColor="white"
              href={SIGNUP_URL}
            >
              Contact us
            </Button>
          </VStack>
        </HStack>

        <Text mt="4" textAlign="center">
          © Polaron Ltd 2024
        </Text>
      </Element>
    </Slice>
  );
};
