import {
  Card,
  Flex,
  Box,
  Heading,
  Text,
  Card,
  Grid,
  GridItem,
} from "@chakra-ui/react";

const paddingX = ["16", "16", "16", "16", "32"];
const bg = 'white'
const text = 'black'
const CaseStudyVideo = () => {
  return (
    <Box flex="1" px={["8", "32", "32", "8"]} mt={[8, 8, 8, 0]}>
      <Card
        p="4"
        variant="outline"
        colorScheme="brand"
        borderRadius="32px"
        backgroundColor="#ffffff"
      >
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{ maxHeight: "250px", width: "auto" }}
        >
          <source
            src={require("url:../assets/micro-explore.ad48314a.mp4")}
            alt="Polaron Li-Ion case study exploration"
            loading="lazy"
          />
        </video>
      </Card>
    </Box>
  );
};

const CaseStudiesCutout = () => {
  return (
    <Card
      variant="filled"
      backgroundColor={bg}
      borderRadius="0 32px 0 32px"
      px={paddingX}
      color={text}
      mt="4"
      py="8"
      // style={{ position: "absolute", bottom: 0 }}
    >
      <Text fontSize="xl"><b>10%</b> improvement in power density.</Text>
      <Text fontSize="xl" mt="4">
      <b>300x</b> speed up of image acquisition.
      </Text>
      <Text fontSize="xl" mt="4">
      <b>50%</b> equivalent cost saving.
      </Text>
      <Text fontSize="xl" mt="4">
      <b>1000s</b> design variations tested in under a day.
      </Text>
      
    </Card>
  );
};

export const CaseStudy = () => {
  const stackedAreas = `"title"
    "video"
    "subheading"
    "cutout"`;
  const sideBySideAreas = `"title video"
    "subheading video"
    "cutout video"`;
  return (
    <Box backgroundColor={bg} m="16" pt="16" mt="32" borderRadius="32px" border='solid' >
      <Grid
        templateAreas={[
          stackedAreas,
          stackedAreas,
          stackedAreas,
          sideBySideAreas,
        ]}
      >
        <GridItem area={"title"}>
          <Box px={paddingX}>
            <Heading color={text} size="lg" mb="2">
              Case study: Optimising a Li-ion battery electrode
            </Heading>
          </Box>
        </GridItem>
        <GridItem area={"subheading"}>
          <Box px={paddingX}>
            <Text color={text} fontSize="sm" mt="4">
            The relationship between electrode processing and the microstructure is complex, making design optimisation challenging. Using Polaron, the relationship between two key processing steps (mixing and calendering) and microstructure was learned from only 9 images.
            </Text>
          </Box>
        </GridItem>
        <GridItem area={"cutout"}>
          <CaseStudiesCutout />
        </GridItem>
        <GridItem area={"video"}>
          <CaseStudyVideo />
        </GridItem>
      </Grid>
    </Box>
  );
};
