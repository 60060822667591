import React from 'react';
import TermsOfUseDD from '../assets/terms-of-use.mdx'
import { Box } from '@chakra-ui/react';

export const TermsOfUse = () => {
  return (
    <div>
      <Box m={16} px={10}>
      <TermsOfUseDD />
      </Box>
    </div>
  );
};