import React from "react";
import { Navbar } from "./components/Navbar";
import { Landing } from "./components/Landing";
import { ChakraProvider, extendTheme, Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { WaitingList } from "./components/WaitingList";
import { Benefits } from "./components/Benefits";
import { Platform } from "./components/Platform";
import { CaseStudy } from "./components/CaseStudy";
import { AboutUs } from "./components/AboutUs";
import { Research } from "./components/Research";
import { Careers } from "./components/Careers"
import { Footer } from "./components/Footer";
import { PrivacyPolicy } from "./components/PrivacyPolicy";
import "./styles.css";
import { TermsOfUse } from "./components/TermsOfUse";

export const SIGNUP_URL = "https://cy54lwgpzbk.typeform.com/to/PyjsVc9g";

const theme = extendTheme({
  styles: {
    global: {
      ul: {
        pl: 10, // Adjust this value to control the indentation
        listStyleType: 'disc', // Default bullet style
      },
      ol: {
        pl: 6, // Adjust this value to control the indentation
        listStyleType: 'decimal', // Default ordered list style
      },
      li: {
        marginBottom: '0.5rem', // Space between list items
      },
      table: {
        width: '100%',
        borderCollapse: 'collapse', // Remove any spacing between borders
        mt: 4,
        mb: 4,
      },
      th: {
        borderBottom: '2px solid',
        borderColor: 'gray.300',
        padding: '12px 8px',
        textAlign: 'left',
        backgroundColor: 'gray.50',
        fontWeight: 'bold',
        '&:first-child': {
          borderLeft: 'none',
        },
        '&:last-child': {
          borderRight: 'none',
        },
      },
      td: {
        borderBottom: '1px solid',
        borderColor: 'gray.200',
        padding: '12px 8px',
        '&:first-child': {
          borderLeft: 'none',
        },
        '&:last-child': {
          borderRight: 'none',
        },
      },
      tr: {
        '&:last-child td': {
          borderBottom: 'none', // Remove bottom border from the last row
        },
      },
    },
  },
  colors: {
    black: "#0A0A0A",
    white: "#F8FAFF",
    purple: "#6617F6",
    brand: {
      100: "#0A0A0A",
      200: "#0A0A0A",
      300: "#0A0A0A",
      400: "#0A0A0A",
      500: "#0A0A0A",
    },
    gray: {
      100: "#E0E0E0",
      200: "#dedede",
      300: "#C2C2C2",
      400: "#a0a0a0",
      500: "#858585",
      800: "",
    },
    accent: {
      500: "#D1F752",
    },
  },
  fonts: {
    body: "Inter, sans-serif",
    heading: "Inter, sans-serif",
    // heading: "'IBM Plex Mono', monospace",
  },
  components: {
    Button: {
      variants: {
        outline: {
          borderColor: "brand.500",
          color: "brand.500",
        },
      },
    },
    Input: {
      defaultProps: {
        focusBorderColor: "brand.500",
        focusBackgroundColor: "brand.100",
      },
    },
    Textarea: {
      defaultProps: {
        focusBorderColor: "brand.500",
        focusBackgroundColor: "brand.100",
      },
    },
    NumberInput: {
      defaultProps: {
        focusBorderColor: "brand.500",
        focusBackgroundColor: "brand.100",
      },
    },
    Select: {
      defaultProps: {
        focusBorderColor: "brand.500",
        focusBackgroundColor: "brand.100",
      },
    },
  },
});

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Router>
        <Box w="100%">
          <Navbar />
          <Routes>
            <Route path="/" element={<>
              <Landing />
              <Platform />
              <Benefits />
              <CaseStudy />
              <AboutUs />
              <Careers />
              <Research />
              </>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
          </Routes>
          <Footer />
        </Box>
      </Router>
    </ChakraProvider>
  );
};
