import { Box, Button, Link } from "@chakra-ui/react";
import { Slice } from "./Slice";
import { SIGNUP_URL } from "../App";

export const WaitingList = () => {
  return (
    <Slice>
      <Box
        my={8}
        display="flex"
        p={0}
        alignItems="center"
        justifyContent="center"
      >
        <Button
          fontSize="lg"
          size="lg"
          as={Link}
          colorScheme="brand"
          href={SIGNUP_URL}
          variant="outline"
          borderWidth="2px"
          m="auto"
          px="12"
        >
          Want to try Polaron?
        </Button>
      </Box>
    </Slice>
  );
};
