import { Heading, SimpleGrid, Box, HStack, Spacer } from "@chakra-ui/react";
import { Slice } from "./Slice";
import slicegan from "../assets/slicegan.png";
import fusion from "../assets/fusion.png";
import kintsugi from "../assets/kintsugi.png";
import characterisation from "../assets/characterisation.png";
import { Element } from "./Element";
import faradayLogo from "../faraday-logo.png";
import imperialLogo from "../Imperial-logo.svg";
import vccLogo from "../vcc-logo.png";
import wmgLogo from "../wmg-2016.png"
import ManchesterPrize from "../assets/manchester-prize.png"

const papers = [
  [slicegan, "https://www.nature.com/articles/s42256-021-00322-1"],
  [fusion, "https://onlinelibrary.wiley.com/doi/full/10.1002/aenm.202202407"],
  [kintsugi, "https://iopscience.iop.org/article/10.1149/1945-7111/ac7a68"],
  [characterisation, "https://pubs.acs.org/doi/10.1021/acsenergylett.2c01996"],
];

const ResearchCard = ({ children }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {children}
    </Box>
  );
};

export const Research = () => {
  return (
    <Slice id="research">
      <Element>
        <Heading textAlign="center" size="xl" mb="2" mt="16">
          Based on world leading research
        </Heading>
        <SimpleGrid mt="16" columns={[2, 2, 4]} spacing={8}>
          {papers.map(([paper, href]) => (
            <ResearchCard key={href}>
              <a href={href} target="_blank">
                <img src={paper} />
              </a>
            </ResearchCard>
          ))}
        </SimpleGrid>

        <HStack mt="16">
              <Box mr="3">
                <img
                  style={{ height: "48px", width: "auto" }}
                  src={imperialLogo}
                />
              </Box>
              <Spacer />
              <Box mr="3">
                <img
                  style={{ height: "48px", width: "auto" }}
                  src={faradayLogo}
                />
              </Box>
              <Spacer />
              <Box mr="3">
                <img style={{ height: "48px", width: "auto" }} src={vccLogo} />
              </Box>
              <Spacer />
              <Box mr="3">
                <img style={{ height: "48px", width: "auto" }} src={wmgLogo} />
              </Box>
              <Spacer />
              <Box mr="3">
                <img style={{ height: "48px", width: "auto" }} src={ManchesterPrize} />
              </Box>
            </HStack>

      </Element>
    </Slice>
  );
};
