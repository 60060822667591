import {
  VStack,
  Text,
  Flex,
  Box,
  Heading,
  Img,
  SimpleGrid,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { Slice } from "./Slice";

import isaac from "../assets/Isaac - Shoreditch.jpeg";
import steve from "../assets/Steve - Shoreditch.jpeg";
import sam from "../assets/Sam - Shoreditch.jpeg";
import chris from "../assets/Chris - Shoreditch.jpeg";
import elena from "../assets/Elena - Shoreditch.jpg";
import { Element } from "./Element";
import pressShot from "../assets/POLARON-press-shot-landscape.png";
import ronan from '../assets/Ronan - Shoreditch.jpg'

export const Pictures = () => {
  return (
    <Grid
      templateAreas={[
        `"top"
        "bottom1"
        "bottom2"`,
        `"top"
        "bottom1"
        "bottom2"`,
        `"top"
        "bottom1"
        "bottom2"`,
        `"top top"
        "bottom1 bottom2"`,
      ]}
    >
      <GridItem mt="2" area={"top"}>
        <Img
          borderRadius="32px"
          maxH="400px"
          w="auto"
          m="auto"
          src={pressShot}
        />
      </GridItem>
      {/* <GridItem mt="2" area={"bottom1"}>
        <Img borderRadius="32px" maxH="200px" w="auto" m="auto" src={team2} />
      </GridItem>
      <GridItem mt="2" area={"bottom2"}>
        <Img borderRadius="32px" maxH="200px" w="auto" m="auto" src={team1} />
      </GridItem> */}
    </Grid>
  );
};

export const AboutUs = () => {
  return (
    <Slice id="about">
      <Element>
        <Heading size="xl" mb="2" mt="16" textAlign="center">
          About us
        </Heading>
        <SimpleGrid mt="8" columns={[1, 1, 2]} spacing={8}>
          <Pictures />
          <Box flex="1" p="4">
            <Text fontSize="md" mt="4">
            Polaron is a spin-out from Imperial College London, founded by Isaac Squires, Dr. Steve Kench, and Dr. Sam Cooper. The founders were united by their desire to harness engineering, artificial intelligence, and materials science to build the materials of the future.
            </Text>
            <Text fontSize="md" mt="4">
            Our mission is to become the world leaders at the interface between AI and materials. Through relentless dedication to innovation and pragmatism, we aim to support the next generation of advanced materials critical to building a more sustainable future.
            </Text>
          </Box>
          </SimpleGrid>
          <SimpleGrid mt="8" columns={[1, 2, 3]} spacing={4}>
          <Box flex="1" p="4">
              <Img
              borderRadius="32px"
              maxH="200px"
              w="auto"
              m="auto"
              src={isaac}
            />
            <Text mt="2" fontSize="md" textAlign='center'>
              CEO and Co-Founder, Isaac Squires
            </Text>
              </Box>

              <Box flex="1" p="4">
              <Img
              borderRadius="32px"
              maxH="200px"
              w="auto"
              m="auto"
              src={steve}
            />
            <Text mt="2" fontSize="md" textAlign='center'>
              CTO and Co-Founder, Dr Steve Kench
            </Text>
              </Box>

              <Box flex="1" p="4">
              <Img
              borderRadius="32px"
              maxH="200px"
              w="auto"
              m="auto"
              src={sam}
            />
            <Text mt="2" fontSize="md" textAlign='center'>
              Chief Scientist, Dr Sam Cooper
            </Text>
              </Box>

              <Box flex="1" p="4">
              <Img
              borderRadius="32px"
              maxH="200px"
              w="auto"
              m="auto"
              src={chris}
            />
            <Text mt="2" fontSize="md" textAlign='center'>
              Head of Engineering, Chris Jones
            </Text>
              </Box>

              <Box flex="1" p="4">
              <Img
              borderRadius="32px"
              maxH="200px"
              w="auto"
              m="auto"
              src={elena}
            />
            <Text mt="2" fontSize="md" textAlign='center'>
              Business Development Intern, Elena Wan
            </Text>
              </Box>

              <Box flex="1" p="4">
              <Img
              borderRadius="32px"
              maxH="200px"
              w="auto"
              m="auto"
              src={ronan}
            />
            <Text mt="2" fontSize="md" textAlign='center'>
              Machine Learning Engineer, Ronan Docherty
            </Text>
              </Box>

        </SimpleGrid>
      </Element>
    </Slice>
  );
};
