import { Box } from "@chakra-ui/react";

export const Slice = ({
  children,
  id,
  h,
  mt,
  pb,
  backgroundColor = "white",
  rounded = false,
  roundedBottom = false,
  roundedTop = false,
  style = undefined,
}) => {
  return (
    <Box
      id={id}
      h={h}
      backgroundColor={backgroundColor}
      mt={mt}
      borderRadius={
        rounded
          ? "32px"
          : roundedBottom
          ? "0 0 32px 32px"
          : roundedTop
          ? "32px 32px 0 0"
          : "0"
      }
      pb={pb + (rounded || roundedBottom ? 8 : 0)}
      pt={8 + (rounded || roundedTop ? 8 : 0)}
      px={8}
      style={style}
      // mx={rounded || roundedBottom ? 8 : 0}
    >
      {children}
    </Box>
  );
};
