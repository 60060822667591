import {
    Text,
    Flex,
    Box,
    Heading,
    VStack,
    Card,
    SimpleGrid,
    Grid,
    Center
} from "@chakra-ui/react";
import clock from '../assets/clock.png'
import magnify from '../assets/magnify.png'
import graph from '../assets/graph.png'
import pound from '../assets/pound.png'

export const ListItemIcon = ({ title, content, img }) => {
    return (
      <Box 
        m="4" 
        p="0" 
        position="relative"
      >
  
        <Card
          variant="none"
          p="4"
          pr="8"
          pl="8"
          backgroundColor="none"
          position="relative"
          zIndex="1"
          align='center'
        >
          <img width="100px" src={img} alt="icon"></img>
          <Text>{title}</Text>
          <Text fontSize="sm" mt={4}>
            {content}
          </Text>
        </Card>
      </Box>
    );
  };

export const Benefits = () => {

    return (
      <Box  pb={32}  borderRadius="32px" p={8}>
        <Box align='center'>
        <Heading size="xl" mb="2" mt="16" mx="auto">
            Next-generation material design, supercharged with AI.
          </Heading>
          </Box>
        <SimpleGrid columns={[1,1,1]} spacing={8} mt='12' mx='32'>
            <Box >
              <ul style={{ listStyle: "none" }}>
              <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}  gap={0}>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItemIcon
                      title="Prototype less"
                      content="Reduce the number of prototyping steps, turning 100s weeks into 100s hours for new designs."
                      img={clock}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItemIcon
                      title="Gain deeper insights"
                      content="AI-enhanced characterisation techniques provide high quality 3D datasets 100x faster than conventional imaging."
                      img={magnify}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItemIcon
                      title="Make better materials"
                      content="Leverage AI-driven optimisation to identify the highest performing materials and how to make them."
                      img={graph}
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItemIcon
                      title="Reduce costs"
                      content="Integrated cost optimisation to reduce the cost of manufacturing, whilst cutting costs of development."
                      img={pound}
                    />
                  </Box>
                </Box>
              </Grid>
              </ul>
            </Box>
          </SimpleGrid>
      </Box>
    );
  };


