import {
  VStack,
  Text,
  Flex,
  Box,
  Heading,
  Img,
  SimpleGrid,
  Grid,
  GridItem,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  LinkBox,
  LinkOverlay
} from "@chakra-ui/react";
import { Slice } from "./Slice";

import React, {useState, useEffect} from "react";

import team1 from "../team1.jpg";
import team2 from "../team2.jpg";
import { Element } from "./Element";
import pressShot from "../chris_steve_office.jpg";

export const Pictures = () => {
  return (
    <Grid
      templateAreas={[
        `"top"
        "bottom1"
        "bottom2"`,
        `"top"
        "bottom1"
        "bottom2"`,
        `"top"
        "bottom1"
        "bottom2"`,
        `"top top"
        "bottom1 bottom2"`,
      ]}
    >
      <GridItem mt="2" area={"top"}>
        <Img
          borderRadius="32px"
          maxH="400px"
          w="auto"
          m="auto"
          src={pressShot}
        />
      </GridItem>
    </Grid>
  );
};

export const JobsTable = () => {
  const [jobs, setJobs] = useState([]);

  useEffect(() => {
    const options = { method: 'GET', headers: { accept: 'application/json' } };
    fetch('https://apply.workable.com/api/v1/widget/accounts/polaron?details=true', options)
      .then(response => response.json())
      .then(data => {
        setJobs(data.jobs);
      })
      .catch(err => console.error(err));
  }, []); // Empty dependency array means this effect runs once on mount

  return (
    <Table variant="simple" mt="8">
      <Thead>
        <Tr>
          <Th>Job Title</Th>
          <Th>Location</Th>
        </Tr>
      </Thead>
      <Tbody>
      {jobs.length === 0 ? (
            <LinkBox 
            as="tr" 
          >
            <Td>
              No job openings currently.
          </Td>
          <Td></Td>
          </LinkBox>
        ) : ( <>
        {jobs.map(job => (
          <LinkBox 
            as="tr" 
            key={job.shortcode} 
            _hover={{ bg: "gray.200", cursor: "pointer" }}
          >
            <Td>
              <LinkOverlay href={job.application_url} isExternal>
                {job.title}
              </LinkOverlay>
            </Td>
            <Td>{job.city}, {job.country}</Td>
          </LinkBox>
        ))}
        </>)}
        
      </Tbody>
    </Table>
  );
}

export const Careers = () => {

  return (
    <Slice id="careers">
      <Element>
        <Heading size="xl" mb="2" mt="16" textAlign="center">
          Careers
        </Heading>
        <SimpleGrid mt="8" columns={[1, 1, 2]} spacing={8}>
          
          <Box flex="1" p="4">
            <Text fontSize="md" mt="4">
            At Polaron, we are building a brilliant team that thrive on collaboration, innovation, and excellence.
            </Text>
            <Text fontSize="md" mt="4">
            Our team has a diverse range of skills and interests. We are looking for creative and critical thinkers interested in solving exciting new problems at the vanguard of materials science, software engineering, and machine learning. 
            </Text>
            <Text fontSize="md" mt="4">
            If you are passionate about building great tools, developing cutting-edge AI, or accelerating the design of materials, then please apply for one of our open positions. If you don't see something that fits, but are still interested in joining the team, send a CV to <u><a href='mailto:careers@polaron.ai'>careers@polaron.ai</a></u>.
            </Text>
          </Box>
          <Pictures />
        </SimpleGrid>
        <JobsTable />
      </Element>
    </Slice>
  );
};
