import {
  Text,
  Flex,
  Box,
  Heading,
  VStack,
  Card,
  SimpleGrid,
  Grid,
} from "@chakra-ui/react";
import { Slice } from "./Slice";
import { Element } from "./Element";
import platformImage from "../platform.png";


export const PlatformHeader = ({ backgroundColor, color, text }) => {
  return (
    
    <Card
      flex="1"
      p="4"
      variant="none"
      border="1px solid"
      color={backgroundColor}
      style={{
        position: "sticky",
        top: "6rem",
        zIndex: 2,
        scrollPaddingTop: "8rem",
      }}
    >
      <Heading
        textAlign="center"
        fontWeight="normal"
        size={["md", "md", "md", "lg"]}
      >
        {text}
      </Heading>
      
    </Card>
  );
};

export const ListItem = ({ title, content }) => {
  return (
    <Box 
      m="4" 
      p="0" 
      borderRadius="md" 
      position="relative"
      _hover={{
        '&::before': {
          content: '""',
          position: "absolute",
          top: "-1px",
          left: "-1px",
          right: "-1px",
          bottom: "-1px",
          borderRadius: "inherit",
          background: "linear-gradient(45deg, #D1F752, #dedede)",
          zIndex: 0,
        },
      }}
      _before={{
        content: '""',
        position: "absolute",
        top: "-1px",
          left: "-1px",
          right: "-1px",
          bottom: "-1px",
        borderRadius: "inherit",
        background: "gray.200",
        zIndex: 0,
      }}
    >
      <Card
        variant="none"
        p="4"
        backgroundColor="white"
        borderRadius="md"
        position="relative"
        zIndex="1"
      >
        <Text fontSize='lg'>{title}</Text>
        <Text fontSize="sm" mt={4}>
          {content}
        </Text>
      </Card>
    </Box>
  );
};


export const Platform = () => {
  return (
    <Slice id="platform">
      <Element>
        <VStack>
          <Heading size="xl" mb="2" mt="16" mx="auto">
            Unlock the power of image data.
          </Heading>
          
          <Box maxW="1000px" flex="1" m={8}>
            <img src={platformImage} alt="Platform" />
          </Box>
          
          <SimpleGrid columns={[1,1,1]} spacing={8}>
            <Box mx={16} align='center' fontSize='xl'>
Polaron is a material design tool that takes microstructural image data and trains bespoke AI models to perform enhanced characterisation and design optimisation.
            </Box>
            <Box>
              <ul style={{ listStyle: "none" }}>
              <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}  gap={0}>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItem
                      title="AI-enhanced image processing"
                      content="Efficiently and accurately turn raw micrographs into machine learning ready datasets with advanced segmentation tools."
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItem
                      title="2D to 3D reconstruction"
                      content="From a single 2D image reconstruct a 3D volume. This removes the need for expensive and slow 3D imaging techniques."
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItem
                      title="Process-structure prediction"
                      content="Train models that learn the relationship between process and microstructure. This enables prediction of new microstructure in seconds."
                    />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap={0}>
                  <Box as="li">
                    <ListItem
                      title="Optimise design for desired specification"
                      content="Rapidly identify the process parameters that produce materials with the desired properties with multi-objective optimisation."
                    />
                  </Box>
                </Box>
              </Grid>
              </ul>
            </Box>
          </SimpleGrid>

          
          
        </VStack>
      </Element>
    </Slice>
  );
};
