import React from "react";
import {
  ChakraProvider,
  Flex,
  Box,
  Spacer,
  Heading,
  Link,
  Button,
  Image,
  HStack,
  Hide,
  ButtonGroup,
} from "@chakra-ui/react";
import pai from "../LOGO-BLACK.svg";
import { SIGNUP_URL } from "../App";

export const SIGN_IN_URL = "https://app.polaron.ai";

export const Navbar = () => {
  return (
    <HStack
      height="6rem"
      px="8"
      backgroundColor="white"
      style={{
        position: "sticky",
        top: 0,
        zIndex: 2,
        boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
      }}
    >
      <Box>
        <HStack>
          <Box mr="2">
            <img src={pai} alt="Logo" height="24px" width="24px" />
          </Box>
          <Heading fontFamily="Inter" size="lg" as={Link} href="#">
            Polaron
          </Heading>
        </HStack>
      </Box>
      <Spacer />
      <Hide below="md">
        <Box>
          <Link mr="12" fontSize="md" href="/#platform">
            Polaron
          </Link>
          <Link mr="12" fontSize="md" href="/#about">
            About
          </Link>
          <Link mr="12" fontSize="md" href="/#careers">
            Careers
          </Link>
          <Link mr="12" fontSize="md" href="/#research">
            Research
          </Link>
        </Box>
      </Hide>
      <Spacer />
      <Box>
        <ButtonGroup>
          <Button as={Link} colorScheme="brand" href={SIGNUP_URL}>
            Request a Demo
          </Button>
          <Button variant="ghost" as={Link} href={SIGN_IN_URL} target="_blank">
            Login
          </Button>
        </ButtonGroup>
      </Box>
    </HStack>
  );
};
