import React from 'react';
import PrivacyPolicyMD from '../assets/privacy-policy.mdx'
import { Box, Center } from '@chakra-ui/react';

export const PrivacyPolicy = () => {
  return (
    <div>
        <Box m={16} px={10}>
      <PrivacyPolicyMD />
      </Box>
    </div>
  );
};
