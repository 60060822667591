**Polaron Terms Of Use**

<br></br>

These terms tell you the rules for using our website https://polaron.ai/ (**our site**) and the Polaron platform (**our platform**).

<br></br>

**Who we are and how to contact us**   
[https://polaron.ai/](https://polaron.ai/) is a site operated by Polaron Ltd (**we, our, us**). We are a limited company registered in England and Wales under company number 14882059 and have our registered office at 30 Upper High Street, Thame, Oxfordshire, England, OX9 3EZ. 

<br></br>

To contact us, please email [legal@polaron.ai](mailto:legal@polaron.ai).  

<br></br>

**By using our site and our platform you accept these terms**  
By using our site or our platform, you confirm that you accept these terms of use and that you agree to comply with them. If you do not agree to these terms, you must not use our site or our platform. We recommend that you print a copy of these terms for future reference.

<br></br>

**There are other terms that may apply to you**  
These terms of use refer to the following additional terms, which also apply to your use of our site and our platform:

<br></br>

* Our [**Privacy Policy**](https://polaron.ai/privacy-policy) which sets out information about [how](\#heading) we process your personal data.  
* You may have access to our platform by virtue of the organisation that you represent entering into a separate legal agreement with us (**Platform Agreement**).  The terms of the Platform Agreement shall also apply to your use of the platform.

<br></br>

**We may make changes to these terms**  
We amend these terms from time to time. Every time you wish to use our site or our platform, please check these terms to ensure you understand the terms that apply at that time. 

<br></br>

**We may make changes to our site and our platform**  
We may update and change our site or our platform from time to time.

<br></br>

**We may suspend or withdraw our site and our platform**  
Our site is made available free of charge. We do not guarantee that our site, our platform, or any content on them, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site or our platform for business and operational reasons. 

<br></br>

You are also responsible for ensuring that all persons who access our site and our platform through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.

<br></br>

**We may transfer this agreement to someone else**  
We may transfer our rights and obligations under these terms to another organisation. We will tell you in writing if this happens and we will ensure that the transfer will not affect your rights under these terms.

<br></br>

**You must keep your account details safe**  
If you choose, or you are provided with, a username, password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.

<br></br>

We have the right to disable any username or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use or the applicable Platform Agreement.

<br></br>

If you know or suspect that anyone other than you knows your username or password, you must promptly notify us at [support@polaron.ai](mailto:support@polaron.ai). 

<br></br>

**How you may use material on our site and our platform**  
We are the owner or the licensee of all intellectual property rights in our site and our platform (including all underlying software), and in the material published on them. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.

<br></br>

You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation to content posted on our site. You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text. Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged. You must not use any part of the content on our site or our platform for commercial purposes without obtaining a licence to do so from us or our licensors. If you print off, copy, download, share or repost any part of our site or platform in breach of these terms of use, your right to use our site and platform will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.

<br></br>

You may not use our site or platform in any way that is unlawful, fraudulent or has any unlawful or fraudulent purpose or effect.

<br></br>

All use of content accessible through our platform must be used in accordance with the Platform Agreement.

<br></br>

**No text or data mining, or web scraping**  
You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our site, our platform or any services provided via, or in relation to, our site or our platform. This includes using (or permitting, authorising or attempting the use of):

<br></br>

* Any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of the site, platform or any data, content, information or services accessed via the same.  
* Any automated analytical technique aimed at analysing text and data in digital form to generate information which includes but is not limited to patterns, trends and correlations.

<br></br>

The provisions in this clause should be treated as an express reservation of our rights in this regard, including for the purposes of Article 4(3) of Digital Copyright Directive (*(EU) 2019/790*).

<br></br>

This clause shall not apply insofar as (but only to the extent that) we are unable to exclude or limit text or data mining or web scraping activity by contract under the laws which are applicable to us. 

<br></br>

**Platform Restrictions**

<br></br>

## Where you access the platform, you undertake (except as permitted by any local law):

<br></br>

* To only access the platform in accordance with the applicable Platform Agreement and with a username and password created specifically for you.   
* Not to rent, lease, sub-license, loan, translate, merge, adapt, vary or modify the platform or any content on the platform.  
* Not to make alterations to, or modifications of, the whole or any part of the platform, nor permit the platform or any part of it to be combined with, or become incorporated in, any other programs.  
* Not to disassemble, decompile, reverse-engineer or create derivative works based on the whole or any part of the platform or content contained in the platform nor attempt to do any such thing except to the extent that such actions cannot be prohibited under law.  
* Not to provide or otherwise make available the platform in whole or in part (including but not limited to program listings, object and source program listings, object code and source code and any content, data or information), in any form to any person other than those permitted to have access under the applicable Platform Agreement without prior written consent from us.  
* Not to use the platform in a way that could damage, disable, overburden, impair or compromise our systems or security.  
* Not to collect or harvest any information or data from the platform or our systems or attempt to decipher any transmissions to or from any servers. 

<br></br>

Whenever you make use of a feature that allows you to create content on our platform, upload or share content to our platform, such content must not:

<br></br>

* Be defamatory, obscene, offensive, hateful or inflammatory.  
* Infringe any copyright, database right, trade mark or any third party intellectual property rights.  
* Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.  
* Contain illegal content or promote any illegal content or activity.  
* Be in contempt of court.  
* Be threatening, abuse or invade another's privacy, or cause annoyance, inconvenience or needless anxiety.

We have the right to remove any content you make on our platform if, in our opinion, your post does not comply with these terms. If you breach these terms or the applicable Platform Agreement, we may immediately suspend or terminate your access to the platform.

<br></br>

**Do not rely on information on this site**  
The content on our site and our platform is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site and our platform.

<br></br>

Although we make reasonable efforts to update the information on our site and our platform, we make no representations, warranties or guarantees, whether express or implied, that the content on our site and our platform is accurate, complete or up to date.

<br></br>

**We are not responsible for websites we link to**  
Where our site and our platform contain links to other websites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those websites or resources.

<br></br>

**Our responsibility for loss or damage suffered by you**

<br></br>

**Whether you are a consumer or a business user**:

<br></br>

* We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation.

<br></br>

**If you are a business user**:

<br></br>

* We exclude all implied conditions, warranties, representations or other terms that may apply to our site or our platform or any content on them.  
* We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:  
* use of, or inability to use, our site or our platform; or  
* use of or reliance on any content displayed on our site or our platform.  
* In particular, we will not be liable for:  
* loss of profits, sales, business, or revenue;  
* business interruption;  
* loss of anticipated savings;  
* loss of business opportunity, goodwill or reputation; or  
* any indirect or consequential loss or damage.  
* If your access to the platform is by virtue of a Platform Agreement, there may be different limitations of liability which apply.

<br></br>

**If you are a consumer user**:

<br></br>

* Please note that we only provide our site for domestic and private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.  
* We do not currently offer consumers the ability to access our platform. 

<br></br>

**We are not responsible for viruses and you must not introduce them**  
We do not guarantee that our site or our platform will be secure or free from bugs or viruses. You are responsible for configuring your information technology, computer programmes and platform to access our site and our platform. You should use your own virus protection software.

<br></br>

You must not misuse our site or our platform by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site or our platform, the servers on which our site or our platform are stored or any server, computer or database connected to our site or our platform. You must not attack our site or our platform via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under the UK Computer Misuse Act 1990\. We will report any such breach to the relevant law enforcement authorities and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site and our platform will cease immediately.

<br></br>

**Which country's laws apply to any disputes?**  
If you are a consumer, please note that these terms, their subject matter and their formation, are (to the degree permitted by applicable law) governed by English law. You and we both agree that the courts of England and Wales will have exclusive jurisdiction except to the degree that this is not permitted under applicable laws.

<br></br>

If you are a business, these terms, their subject matter and their formation (and any non-contractual disputes or claims) are governed by English law. We both agree to the exclusive jurisdiction of the courts of England and Wales.  