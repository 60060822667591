**Polaron Privacy policy**

<br></br>

1. **Important information and who we are** 

**Introduction**   
This privacy policy sets out how Polaron Ltd (company number 14882059\) uses and protects your personal data when you visit the Polaron website, use the Polaron platform, apply to work for us or work with us. This website is not intended for children and we do not knowingly collect data relating to children.

Polaron Ltd is the controller for the Polaron website, platform and is responsible for your personal data (collectively referred to as " **Polaron**", "**we**", "**us**" or "**our**" in this privacy policy).

This privacy policy applies to:

* “**Visitors**” of the website, i.e. someone who is only visiting the website, but is not accessing the Polaron platform.   
* “**Users**” of the Polaron platform.  
* “**Partners**” who work with Polaron, such as our suppliers.  
* “**Applicants**” who apply to work with us.

If you are a User, you or the organisation that you represent (the “**Organisation**”) will have entered into an agreement with Polaron for the use of the Polaron platform (the “**Platform Agreement**”).  Please note that the information you or the Organisation provides to us (whether it is personal or non-personal information) will also be governed by the Platform Agreement. If you have any queries about the processing carried out by the Organisation or your rights in respect of their processing, we recommend that you check the Organisation’s privacy policy.  

<br></br>

2. **How is your personal data collected?**

We use different methods to collect data from and about you including through:

* **Your interactions with us.** You may give us your personal data by filling in online forms or by corresponding with us by post, phone, email or otherwise. This includes personal data you provide when you:  
* apply for our products or services;  
* create an account for the Polaron platform;  
* use the Polaron platform;  
* complete forms on the website;   
* subscribe to our publications;  
* request marketing to be sent to you;   
* apply to work with us;   
* discuss how we can work together; or  
* give us feedback or contact us.  
    
* **Automated technologies or interactions.** As you interact with our website and platform, we will automatically collect technical data about your equipment, browsing actions and patterns. Technical data includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, device ID and other technology on the devices you use to access this website, as well as patterns of usage of the website and platform for product analytics, We collect this personal data by using cookies and other similar technologies. Please see section 4 for more information on cookies.

* **Third parties or publicly available sources.** We will receive personal data about you from various third parties including publicly available databases such as Companies House and LinkedIn. For Applicants, we may receive your personal data from: recruitment agencies, your referees, LinkedIn, Indeed, Workable, Handshake and other online recruitment platforms.  

<br></br>

3. **How we use your personal data**

**Legal basis**  
The law requires us to have a legal basis for collecting and using your personal data. We rely on one or more of the following legal bases:

* **Performance of a contract with you:** Where we need to perform the contract we are about to enter into or have entered into with you.  
* **Legitimate interests:** We may use your personal data where it is necessary to conduct our business and pursue our legitimate interests, for example to prevent fraud and enable us to give you the best and most secure experience. We make sure we consider and balance any potential impact on you and your rights (both positive and negative) before we process your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law).  
* **Legal obligation:** We may use your personal data where it is necessary for compliance with a legal obligation that we are subject to. We will identify the relevant legal obligation when we rely on this legal basis.  
* **Consent:** We rely on consent only where we have obtained your active agreement to use your personal data for a specified purpose, for example if you subscribe to an email newsletter.

**Purposes for which we will use your personal data**  
We have set out below, in a table format, a description of all the ways we plan to use the various categories of your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.

| Purpose/Use | Type of data | Legal basis  |
| :---- | :---- | :---- |
| To enable us to provide you with access to and use of the Polaron platform as per the Platform Agreement. | Your name, Organisation (if applicable), username, password, phone number, email address and address details (if you are part of an Organisation then the phone number, email address and address should be your work details). | Legitimate Interest – to allow us to perform the Platform Agreement (if you are part of an Organisation).  Performance of our contract with you. |
| To process and deliver your order including: (a) Manage payments, fees and charges. (b) Collect and recover money owed to us. | Bank, credit or debit card details. Details of the Platform Agreement applicable to your use of the Polaron platform. | Legitimate Interest – to allow us to perform the Platform Agreement (if you are part of an Organisation).  Performance of our contract with you.  |
| To manage our relationship with you which will include: (a) Notifying you about changes to our terms or privacy policy. (b) Dealing with your requests, complaints and queries. (c) Woking with you as a Partner.  | Name, username, email address, details related to your Organisation and other details as needed. | Necessary to comply with a legal obligation. Necessary for our legitimate interests (to keep our records updated and manage our relationship with you).  |
| To administer and protect our business, platform and the website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data). | Name, username, email address, details related to your Organisation, technical data (as explained in more detail in section 2).    | Necessary for our legitimate interests (for running our business, provision of services, network security and to prevent fraud). Necessary to comply with a legal obligation.  |
| To use data analytics to improve our website, products/services, customer relationships and experiences and to measure the effectiveness of our communications and marketing. | Technical data (as explained in more detail in section 2), data related to your usage of the website and Polaron platform. | Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, artificial intelligence learning withing our Polaron platform, products and services, to develop our business and to inform our marketing strategy).  |
| To send you relevant marketing communications.  | Your name, Organisation (if applicable), email address and address details (if you are part of an Organisation then the email address and address should be your work details), details of your marketing preferences. | Necessary for our legitimate interests (to carry out direct marketing, develop our products/services and grow our business). Consent, having obtained your prior consent to receiving direct marketing communications.  |
| To carry out market research through your voluntary participation in surveys.  | Your name, Organisation (if applicable), email address, contact details and information you may provide. | Necessary for our legitimate interests (to study how customers use our products/services and to help us improve and develop our products and services). |

**Marketing**  
You may receive marketing communications from us if you have requested information from us or purchased services from us and you have not opted out of receiving the marketing.

You can ask us to stop sending you marketing communications at any time by following the opt-out links within any marketing communication sent to you or by contacting us at legal@polaron.ai.

If you opt out of receiving marketing communications, you will still receive service-related communications that are essential for administrative or customer service purposes.

**Aggregated Data**  
We also collect, use and share aggregated data such as statistical or demographic data which is not personal data as it does not directly (or indirectly) reveal your identity. For example, we may aggregate individuals' usage data to calculate the percentage of users accessing a specific website feature in order to analyse general trends in how users are interacting with our website to help improve the website and our service offering.

**Applicants**  
In connection with your application for work with us, we will collect, store, and use the following categories of personal information about you:

* ## The information you have provided to us in your curriculum vitae, covering letter and application form. This may include: name, title, address, telephone number, personal email address, date of birth, gender, employment history and qualifications.

* ## Any information you provide to us during an interview or application process.

  ## 

If you fail to provide information when requested, which is necessary for us to consider your application (such as evidence of qualifications or work history), we may not be able to process your application successfully.

## 

## We will use the personal information we collect about Applicants to:

* ## Assess skills, qualifications, and suitability for the role.

* ## Carry out background and reference checks, where applicable.

* ## Communicate with you about the recruitment process.

* ## Keep records related to our hiring processes.

* ## Comply with legal or regulatory requirements.

## 

It is in our legitimate interests to decide whether to appoint you to the role since it would be beneficial to our business to appoint someone to that role. We also need to process your personal information to decide whether to enter into a contract of employment or engagement with you.

We may also collect, store and use the following types of more sensitive personal information:

* ## Information about your race or ethnicity, religious beliefs, sexual orientation and political opinions.

* ## Information about your health, including any medical condition, health and sickness records.

  ## 

  ## We will use your sensitive personal information in the following ways:

* ## We will use information about your disability status to consider whether we need to provide appropriate adjustments during the recruitment process, for example whether adjustments need to be made during a test or interview.

* ## We will use information about your race or national or ethnic origin, religious, philosophical or moral beliefs, or your sexual life or sexual orientation, to ensure meaningful equal opportunity monitoring and reporting.

  ## 

You will not be subject to decisions that will have a significant impact on you based solely on automated decision-making.

## 

<br></br>

4. **Cookies**

Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer's hard drive.

We use the following cookies:

* **Strictly necessary cookies.** These are cookies that are required for the operation of our website. These essential cookies are always enabled because our website won’t work properly without them. They include, for example, cookies that enable you to log into secure areas of our website. You can switch off these cookies in your browser settings but you may then not be able to access all or parts of our website.

* **Analytical or performance cookies.** These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.  
    
* **Functionality cookies.** These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).  
    
* **Targeting cookies.** These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. 


You can find more information about the individual cookies we use and the purposes for which we use them in the table below:

| Cookie Title/Cookie Name | Purpose  | Duration |
| :---- | :---- | :---- |
| selectedProject | Store the active project for the user. This cookie is essential for our site and allows you to use our site in a way that makes your user experience more convenient, by automatically opening your last worked on project. | Indefinite |
| session | Store the active session information. This cookie is essential for our site and allows you to use our site in a way that makes your user experience more convenient by allowing you to store your session information, such as login tokens and user ID information. | 1 week |

<br></br>

5. **Disclosures of your personal data**

We may share your personal data where necessary with the parties set out below:

* To contractors, service providers, professional advisers and other third parties we use to support our business and who are bound by contractual obligations to keep personal information confidential and who use it only for the purposes for which we disclose it to them.  
* Third parties to whom we may choose to sell, transfer or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.  
* For any other purpose disclosed by us when you provide the information. With your consent.  
* To comply with any court order, law or legal process, including to respond to any government or regulatory request.  
* To enforce our agreements, including for billing and collection.  
* If disclosure is necessary or appropriate to protect the rights, property, or safety of Polaron, our customers or others. This includes exchanging information with other companies and organisations for fraud protection and credit risk reduction. 

We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes and only permit them to process your personal data for specified purposes and in accordance with our instructions.

<br></br>

6. **International transfers**

We may transfer your personal data to service providers that carry out certain functions on our behalf or to other third parties. This may involve transferring personal data outside the UK and/or European Economic Area (“**EEA**”) to countries which have laws that do not provide the same level of data protection as the UK or EEA. Whenever we transfer your personal data out of the UK or EEA, we ensure a similar degree of protection is afforded to it by ensuring that the following safeguards are in place: 

* We will only transfer your personal data to countries that have been deemed under applicable EEA or UK law to provide an adequate level of protection for personal data.  
* We may use specific standard contractual terms approved for use under UK and EEA law which gives the transferred personal data the same protection as it has in the UK and EEA.  

<br></br>

7. **Data security**

We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality. We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach where we are legally required to do so.

<br></br>

8. **Data retention**

We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or reporting requirements. We may retain your personal data for a longer period in the event of a complaint or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.

To determine the appropriate retention period for personal data, we consider the amount, nature and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal, regulatory, tax, accounting or other requirements.

For unsuccessful Applicants, we will retain your personal information for a period of 6 months after we have communicated to you our decision about whether to appoint you to role. We retain your personal information for that period so that we can show, in the event of a legal claim, that we have not discriminated against candidates on prohibited grounds and that we have conducted the recruitment exercise in a fair and transparent way. After this period, we will securely destroy your personal information in accordance with applicable laws and regulation.

<br></br>

9. **Your legal rights**

You have a number of rights under data protection laws in relation to your personal data.  
You have the right to:

* **Request access** to your personal data (commonly known as a "subject access request"). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully processing it.  
* **Request correction** of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.  
* **Request erasure** of your personal data in certain circumstances. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.  
* **Object to processing** of your personal data where we are relying on a legitimate interest (or those of a third party) as the legal basis for that particular use of your data (including carrying out profiling based on our legitimate interests). In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your right to object. You also have the absolute right to object any time to the processing of your personal data for direct marketing purposes.  
* **Request the transfer** of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.  
* **Withdraw consent** at any time where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.  
* **Request restriction of processing** of your personal data. This enables you to ask us to suspend the processing of your personal data in one of the following scenarios:  
* If you want us to establish the data's accuracy;  
* Where our use of the data is unlawful but you do not want us to erase it;  
* Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or  
* You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.

If you wish to exercise any of the rights set out above, please contact us using the details below.

**No fee usually required**  
You will not have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive. Alternatively, we could refuse to comply with your request in these circumstances.

**What we may need from you**  
We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.

**Time limit to respond**  
We try to respond to all legitimate requests within one month. Occasionally it could take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.

<br></br>

10. **Contact details**

If you have any questions about this privacy policy or about the use of your personal data or you want to exercise your privacy rights, please contact us in the following ways:

* Email address: legal@polaron.ai  
* Postal address: Polaron Ltd, 30 Upper High Street, Thame, Oxfordshire, OX9 3EZ

<br></br>

11. **Complaints**

You have the right to make a complaint at any time to the Information Commissioner’s Office (“**ICO**”), the UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to deal with your concerns before you approach the ICO so please contact us in the first instance.

<br></br>

12. **Changes to the privacy policy and your duty to inform us of changes**

We keep our privacy policy under regular review. It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us, for example a new address or email address.

<br></br>

13. **Third-party links**

This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit. 